<template>
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="2400"
      :filename="
        'Transaction' +
        transactionDetails.Date +
        transactionDetails.TransactionID
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @hasDownloaded="handleDownloaded"
    >
      <section slot="pdf-content">
        <ReceiptForm1 :summaryDetails="summaryDetails" />
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ReceiptForm1 from "./ReceiptForm1.vue";

export default {
  data() {
    return {
      summaryDetails: {},
    };
  },
  components: {
    VueHtml2pdf,
    ReceiptForm1,
  },
  mounted() {
    console.log("transactionDetails", this.transactionDetails);
  },
  props: {
    transactionDetails: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    transactionDetails: {
      immediate: true,
      handler(val) {
        console.log("trans Details :", val);
        this.summaryDetails = val;
      },
    },
    // transactionDetails(val) {
    //   this.summaryDetails = val;
    // },
  },
  methods: {
    handleDownloaded() {
      this.$emit("hasDownloaded");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
