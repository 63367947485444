<template>
  <div class="transaction">
    <loading
      :active="isdownload"
      :is-full-page="true"
      :opacity="0.7"
      color="#ff6b00"
    />

    <div class="page-body">
      <TitleBar :title="$t('summary')" />
      <v-card max-width="1250px" class="mx-auto" md="1100px">
        <v-alert
          v-if="alertMsg.status"
          :timeout="alertMsg.timeout"
          :color="alertMsg.color"
          :type="alertMsg.type"
          dismissible
        >
          {{ alertMsg.text }}
        </v-alert>
        <v-card class="my-5" rounded="lg" elevation="5">
          <v-row>
            <v-col cols="12" class="d-flex justify-content-end">
              <v-col lg="8" md="8" sm="12">
                <v-text-field
                  v-model="search"
                  color="primary"
                  class="v-input-group-append"
                  hide-details
                  :placeholder="$t('search_placeholder')"
                >
                </v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12">
              <v-data-table
                outlined
                fixed-header
                :search="search"
                :loading="isLoading"
                class="elevation-1 custom_table_class"
                :headers="transactionFields"
                :items="transactionArray"
                @item-expanded="handleExpanded"
                dense
                item-key="transaction_id"
                single-expand
                :loading-text="$t('search_placeholder')"
              >
                <template v-slot:item.transaction_id="{ item }">
                  <label class="ellipsis">
                    {{ item.transaction_id }}
                  </label>
                </template>
                <template v-slot:header.beneficiary_name="{}">
                  <span>
                    {{ $t("benificary_name") }} &
                    <v-spacer></v-spacer>
                    {{ $t("relation") }}
                  </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="primary"
                    v-if="hidedownload"
                    class="m-2"
                    @click="handleGeneratePdf(item)"
                    icon
                    :title="$t('download_reciept')"
                    small
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="handleExpanded(item)"
                    icon
                    :title="$t('view_reciept')"
                    small
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <template v-slot:no-data>
                  <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
                  <span>{{ $t("no_data_available") }}</span>
                </template>
                <template v-slot:item.beneficiary_name="{ item }">
                  <div>
                    <label>
                      <span class="text-uppercase">
                        {{ item.beneficiary_name }}</span
                      >
                      <v-spacer></v-spacer>

                      <span class="caption">
                        {{ item.relation_with_remitter }}
                      </span>
                    </label>
                  </div>
                </template>

                <template v-slot:item.upload_files="{ item }">
                  <v-btn
                    v-if="item.is_suspected && !item.upload_files"
                    icon
                    outlined
                    small
                    color="primary"
                    title="Upload File"
                    @click="Fileid(item)"
                    ><v-icon>mdi-upload</v-icon>
                  </v-btn>
                  <img
                    v-if="item.upload_files"
                    class="ml-1"
                    height="40"
                    width="50"
                    :src="item.upload_files"
                    @click="handleImageViewer(item.upload_files, 'showImage')"
                  />
                </template>

                <template v-slot:item.country="{ item }">
                  <div
                    class="d-flex justify-content-center align-items-end mb-2"
                  >
                    <country-flag
                      :country="item.country_code"
                      style="margin-right: 1px"
                    />
                    <span>
                      {{ item.country }}
                    </span>
                  </div>
                </template>
                <template v-slot:item.bank_receipt="{ item }">
                  <v-btn
                    color="success"
                    fab
                    small
                    v-if="
                      item.bank_receipt
                        ? item.bank_receipt.split('.').pop() === 'pdf'
                        : null
                    "
                    @click="openDoc(item.bank_receipt)"
                    title="Download and view"
                    class="ma-2"
                    ><v-icon> mdi-file-download</v-icon></v-btn
                  >
                  <img
                    v-else-if="
                      item.bank_receipt
                        ? item.bank_receipt.split('.').pop() != 'pdf'
                        : null
                    "
                    class="ml-1"
                    height="40"
                    width="50"
                    :src="item.bank_receipt"
                    @click="handleImageViewer(item.bank_receipt, 'showImage')"
                  />
                </template>
                <template v-slot:item.invoice_receipt="{ item }">
                  <v-btn
                    color="success"
                    fab
                    small
                    v-if="
                      item.invoice_receipt
                        ? item.invoice_receipt.split('.').pop() === 'pdf'
                        : null
                    "
                    @click="openDoc(item.invoice_receipt)"
                    title="Download and view"
                    class="ma-2"
                    ><v-icon> mdi-file-download</v-icon></v-btn
                  >
                  <img
                    v-else-if="
                      item.invoice_receipt
                        ? item.invoice_receipt.split('.').pop() != 'pdf'
                        : null
                    "
                    class="ml-1"
                    height="40"
                    width="50"
                    :src="item.invoice_receipt"
                    @click="
                      handleImageViewer(item.invoice_receipt, 'showImage')
                    "
                  />
                </template>

                <template v-slot:item.status="{ item }">
                  <span v-if="item.status === 'Transferred'">{{
                    $t("in_progress_setup")
                  }}</span>
                  <span v-else>
                    {{ item.status }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <html2Pdf
          ref="vuepdf"
          @hasDownloaded="handleDowloaded"
          :transactionDetails="dowloadContent"
        />
        <v-dialog v-model="openDialog" width="500">
          <v-card>
            <v-card-title class="text-h5">Upload File</v-card-title>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-text>
              <v-form ref="uploadFile">
                <label
                  >{{ $t("upload_file_label")
                  }}<span style="color: #f00">*</span></label
                >
                <v-file-input
                  v-model="suspectFile"
                  dense
                  ref="selfiefile"
                  :rules="validations.filerules"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('idtype_selfy_page_placeholder')"
                  outlined
                  prepend-icon=""
                  @change="Imageview($event)"
                >
                  <template v-slot:append>
                    <v-icon
                      aria-hidden="false"
                      class="color-primary"
                      @click="open"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </template>

                  <template v-slot:append-outer>
                    <v-img
                      v-if="suspectFileUrl"
                      class="ml-1"
                      max-height="40"
                      max-width="50"
                      :src="suspectFileUrl"
                      @click="handleImageViewer(suspectFileUrl, '')"
                    />
                  </template>
                </v-file-input>
              </v-form>
            </v-card-text>

            <v-divider class="p-0 m-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="openDialog = false">
                {{ $t("cancel_label") }}
              </v-btn>
              <v-btn color="primary" @click="uploadFile(suspectFile)">
                {{ $t("submit_ind") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="showBusDialog"
          :width="mobilebreakdown() ? '50%' : '100%'"
        >
          <v-card>
            <div class="d-flex justify-space-between color pa-2">
              <div>
                <span class="text-h6 ms-2 mt-2">{{
                  $t("payment_reciept")
                }}</span>
              </div>
              <div>
                <v-btn fab small color="white" @click="showBusDialog = false">
                  <i class="fa-solid fa-xmark"></i>
                </v-btn>
              </div>
            </div>
            <ReceiptForm1 :transactionDetails="dowloadContent" />
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="InfoDialog"
          persistent
          max-width="400"
          elevation="12"
        >
          <v-card>
            <v-card-title class="color-primary font-weight-bold h3 pa-2 m-0">
              {{ $t("qrate") }}
            </v-card-title>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-text class="pa-5 grey--text text-h6">
              {{ $t("cashpay_pin_numb_generator") }}
            </v-card-text>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="InfoDialog = false">
                {{ $t("okay") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <image-viewer
        :showDialog="isImageViewer"
        :picture="{
          PicHeight: '350',
          PicWidth: 600,
          Pic: imageViewerUrl,
        }"
        @closeImageViewer="handleCloseImageViewer"
      ></image-viewer>
    </div>
  </div>
</template>

<script>
import imageViewer from "../../components/tools/imageViewer.vue";
import Loading from "vue-loading-overlay";
import handleImageUpload from "../../mixins/compressImage.mixin";
import { mobilebreakdown, webbreakdown } from "../../utils/breakpoints";
// import NavBar from "@/components/navigations/NavBar.vue";
import TitleBar from "@/components/navigations/TitleBar.vue";
import ReceiptForm1 from "@/components/TransactionSummary/ReceiptForm1.vue";
import html2Pdf from "@/components/TransactionSummary/vue-html-pdf.vue";
// import NavBarLeft from "@/components/navigations/NavBarLeft.vue";
import transactionService from "@/services/TransactionService/transactionService";
import axios from "axios";
import alerts from "@/mixins/alerts";
// import Download from "../../components/TransactionSummary/ReceiptForm1.vue";
export default {
  name: "TransactionSummary",
  components: {
    TitleBar,
    html2Pdf,
    ReceiptForm1,
    imageViewer,
    Loading,
  },
  mixins: [handleImageUpload, alerts],
  data() {
    return {
      mobilebreakdown,
      webbreakdown,
      isdownload: false,
      showBusDialog: false,
      isDownloadReciept: false,
      InfoDialog: false,
      dowloadContent: {},
      suspectFile: null,
      suspectFileUrl: "",
      suspect_remarks: "",
      transaction_id: null,
      openDialog: false,
      isImageViewer: false,
      imageViewerUrl: "",
      hidedownload: true,
      selectedTransactionData: {},
      search: "",
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      transactionFields: [
        {
          text: this.$t("bene_nameand_rel"),
          value: "beneficiary_name",
          sortable: false,
        },
        {
          text: this.$t("Transaction_id"),
          value: "transaction_id",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("Transaction_Date"),
          value: "send_date",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("country"),
          value: "country",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("payment_mode_label"),
          value: "mode_of_payment",
          sortable: false,
        },

        {
          text: this.$t("status"),
          value: "status",
          thStyle: "width:10%",
          sortable: false,
        },
        {
          text: this.$t("sendingAmount"),
          value: "sending_amount",
          sortable: false,
        },
        {
          text: this.$t("CollectedAmount"),
          value: "amount_sent",
          sortable: false,
        },
        {
          text: this.$t("bank_advice_label"),
          value: "bank_receipt",
          sortable: false,
        },
        {
          text: this.$t("invoiceadvice"),
          value: "invoice_receipt",
          sortable: false,
        },

        {
          text: this.$t("upload_file_label"),
          value: "upload_files",
          sortable: false,
        },
        { text: this.$t("remarks"), value: "remarks", sortable: false },
        {
          text: this.$t("payment_reciept"),
          value: "actions",
          thStyle: "width:15%",
          sortable: false,
        },
      ],
      transactionArray: [],
      isLoading: false,
      validations: {
        filerules: [(v) => !!v || "Please upload file."],
      },
    };
  },
  mounted() {
    this.$emit("getProfile");
    this.is_partner = this.$getLocalStorage("is_partner");
    if (this.is_partner) {
      this.hidedownload = false;
    }
    this.getTransactions();
  },

  methods: {
    returnUrl(url) {
      return `${process.env.VUE_APP_TRANSACATION_SERVICE}${url}`;
    },
    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    Fileid(data) {
      this.openDialog = true;
      this.suspect_remarks = data.remarks;
      this.transaction_id = data.id;
    },
    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    handleImageViewer(url, val) {
      if (val === "showImage") {
        this.isImageViewer = !this.isImageViewer;
        this.imageViewerUrl = url;
      } else {
        this.isImageViewer = !this.isImageViewer;
        this.imageViewerUrl = url;
      }
    },
    Imageview(file) {
      const imageurl = URL.createObjectURL(file);
      this.suspectFileUrl = imageurl;
    },
    async uploadFile(fileObject) {
      if (this.$refs.uploadFile.validate()) {
        this.isdownload = true;
        let compressdata = await this.handleImageUpload(fileObject);
        console.log(compressdata);
        let fileData = new FormData();
        fileData.append("remarks_document", compressdata);
        try {
          const responseData = await transactionService.uploadSuspisusFile(
            this.suspect_remarks,
            this.transaction_id,
            fileData
          );
          if (responseData && responseData.data.status_code == 200) {
            this.isdownload = false;
            this.getTransactions();
            this.openDialog = false;
            this.showAlertSuccess(responseData.data.message);
          } else {
            console.log("responseData", responseData);
            this.isdownload = false;
            this.showAlertError(responseData.data.message);
          }
        } catch (error) {
          console.log(error);
          this.isdownload = false;
          this.showAlertError(
            "Error: Something went wrong.Please try again later."
          );
        }
      }
    },
    printDownload() {
      let w = window.open();
      w.document.write(this.$refs.DownloadComp.$el.innerHTML);
      w.document.close();
      w.setTimeout(function () {
        w.print();
      }, 1000);
    },
    handleDowloaded() {
      this.isDownloadReciept = false;
    },
    async handleExpanded(item) {
      this.showBusDialog = true;
      let response = await this.$store.dispatch(
        "Transactional/getTansaction",
        item.id
      );
      this.dowloadContent = (response.data.data && response.data.data[0]) || {};
    },
    async handleGeneratePdf(item) {
      this.isdownload = true;
      const token = sessionStorage.getItem("access_token");
      const axiosconfig = {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };
      await axios
        .get(
          `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/txn/payment-receipt?transaction_id=${item.id}`,
          axiosconfig
        )
        .then((response) => {
          this.isdownload = false;
          const fileName = item.transaction_id + item.send_date + ".pdf";
          const url = new Blob([response.data]);
          console.log("url download", url);
          const reader = new FileReader();

          reader.onload = function () {
            const link = document.createElement("a");
            console.log("data", reader.result);
            link.href = reader.result;
            console.log(" reader.result", reader.result);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          };
          reader.readAsDataURL(url);
        })
        .catch((error) => {
          console.log(error);
          this.isdownload = false;
          return error;
        });
    },
    async getTransactions() {
      this.isLoading = true;
      let response = await this.$store.dispatch(
        "Transactional/getTransactionSummary"
      );
      this.isLoading = false;
      if (response.data.status_code === 200)
        this.transactionArray = response.data.data;
      else this.transactionArray = [];
    },
  },
};
</script>
<style lang="scss">
.main-card-div {
  padding: 1rem !important ;
  padding-top: 2rem !important;
}
.v-input-group-append .v-input__append-inner {
  margin: 0px !important;
}
.v-input-group-append .v-input__slot {
  padding-right: 0px !important;
}
.v-input-group-append .v-btn {
  border-radius: 0px 9px 9px 0px !important;
}
.color {
  background-color: rgb(216, 212, 212);
}
</style>
