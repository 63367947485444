<template>
  <v-row class="pa-1 pt-5 m-auto">
    <v-col>
      <v-card class="ma-auto pb-6">
        <v-row>
          <v-col class="d-flex Justify-center" cols="4">
            <v-card width="auto" flat>
              <img
                src="@/assets/master_logo.png"
                width="100"
                height="60"
                contain
                class="mt-6 ms-5"
              />
            </v-card>
          </v-col>
          <v-col>
            <v-card width="auto" class="ms-8 mt-8" flat>
              <div>
                <b> {{ $t("qqpay_sdn_bhd") }}</b>
              </div>
              <div>
                {{ $t("company_address") }}
                <!-- {{ $t("NO 2, Jalan Kerinchi,Pantai Dalam,59200 Kuala Lumpur") }} -->
              </div>
              <div>
                <!-- {{ $t("kl_gateway_mall") }} -->
                {{ $t("company_sub_address") }}
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-card outlined tile height="4%" color="orange darken-3" class="mt-3">
          <div class="white--text text-center text-h6">
            {{ $t("reciept_label") }}
          </div>
        </v-card>
        <v-row no-gutters v-if="!transactionDetials.PARTNER_TXN_ID">
          <v-col cols="5" class="ps-2">
            {{ $t("transcation_id_receipt_no_temp") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.TransactionID }}
          </v-col>
        </v-row>
        <v-row no-gutters v-if="transactionDetials.PARTNER_TXN_ID">
          <v-col cols="5" class="ps-2">
            {{ $t("transcation_id_receipt_no") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.PARTNER_TXN_ID }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="
            partnerDetails && transactionDetials.PaymentMode === 'Bank Transfer'
          "
        >
          <v-col cols="5" class="ps-2">
            {{ $t("buyer_bank") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.BuyerBank }}
          </v-col>
        </v-row>

        <v-row
          no-gutters
          v-if="partnerDetails && transactionDetials.FPXTransactionID"
        >
          <v-col cols="5" class="ps-2">
            {{ $t("payment_ref_id") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.FPXTransactionID }}
          </v-col>
        </v-row>
        <v-row no-gutters v-if="transactionDetials.PaymentMode === 'Cash Pay'">
          <v-col cols="5" class="ps-2">
            {{ $t("receiverPinNo") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.CASH_PAY_PIN }}
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("txn_status_label") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            <span
              class="ps-1"
              v-if="transactionDetials.TransactionStatus === 'Transferred'"
            >
              In progress</span
            >
            <span class="ps-1" v-else>{{
              transactionDetials.TransactionStatus
            }}</span>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("date") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.Date }}
          </v-col>
        </v-row>

        <v-card outlined tile height="8%" class="mt-3" color="#e9ecef">
          <div class="ps-2 black--text">{{ $t("sender_details") }}</div>
        </v-card>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("sender_name") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.SenderName }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("contact_number") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.ContactNumber }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("address_beneficary") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span> {{ transactionDetials.Address }}
          </v-col>
        </v-row>

        <v-card outlined tile height="8%" class="mt-3" color="#e9ecef">
          <div class="black--text ps-2">{{ $t("beneficary_details") }}</div>
        </v-card>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("beneficary_name") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.BeneficiaryName }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("payment_mode") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.PaymentMode }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="transactionDetials.PaymentMode === 'Bank Transfer'"
        >
          <v-col cols="5" class="ps-2">
            {{ $t("bank") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.Bank }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="transactionDetials.PaymentMode === 'Bank Transfer'"
        >
          <v-col cols="5" class="ps-2">
            {{ $t("account_number_personal") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.AccountNumber }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("country_beneficary") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.Country }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("relationship_beneficary") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.Relationship }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("source_of_fund") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.SourceofFund }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("payment_purpose") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.PaymentPurpose }}
          </v-col>
        </v-row>

        <v-card outlined tile height="8%" class="mt-3" color="#e9ecef">
          <div class="black--text ps-2">
            {{ $t("outwards_remittance_payment_details") }}
          </div>
        </v-card>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("deposited_amount") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.DepositedAmount }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("transfer_fee") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.TransferFee }}
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("send_amount") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.SendAmount }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("exchange_rate") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.ExchangeRate }}
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5" class="ps-2">
            {{ $t("receive_amount") }}
          </v-col>
          <v-col cols="7">
            <span class="pe-2">:</span>
            {{ transactionDetials.ReceiveAmount }}
          </v-col>
        </v-row>

        <v-col>
          <div class="ps-2 pt-2 pb-0 grey--text">
            {{ $t("senders_responsibility_to_notify_the_beneficary") }}
          </div>
          <div class="ps-2 pt-2 pb-0 grey--text">
            {{ $t("sender's responsibility to provide accurate information") }}
          </div>
          <div class="ps-2 pt-2 pb-0 grey--text">
            {{ $t("computer_generated_receipt") }}
          </div>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>
<style scoped>
* {
  font-size: 13px;
}
</style>
<script>
// import { mobilebreakdown, webbreakdown } from "../../utils/breakpoints";
export default {
  data() {
    return {
      transactionDetials: {},
      partnerDetails: true,
    };
  },
  mounted() {
    let partnerUser = this.$getLocalStorage("is_partner");
    if (partnerUser) {
      this.partnerDetails = false;
    }
  },
  props: {
    transactionDetails: {
      type: Object,
    },
    summaryDetails: {
      type: Object,
    },
  },
  watch: {
    transactionDetails: {
      immediate: true,
      handler(val) {
        this.transactionDetials = val;
      },
    },
    summaryDetails: {
      immediate: true,
      handler(val) {
        this.transactionDetials = val;
      },
    },
  },
  methods: {
    async getTransactionDetails(id) {
      let response = await this.$store.dispatch(
        "Transactional/getTansaction",
        id
      );
      this.transactionDetials = (response.data && response.data[0]) || {};
    },
  },
};
</script>
